import Vue from 'vue/dist/vue';
import VueAutosize from 'vue-autosize';
import { formSending } from '@library/forms/form-sending';
import { formValidation, ValidationError } from '@library/forms/validation';
import { MODALS, VALIDATION_RULES } from '@scripts/constants';
import { TRANSLATED_VALIDATION_MESSAGES } from '@scripts/translations';
import { apiFeedbackCreate } from '@scripts/api-methods';
import { sendFeedback } from '@scripts/analytics';
import { form } from '@scripts/mixins/form';
import UIkit from '../../../vendor/uikit/uikit';

Vue.use(VueAutosize);

const formDataScheme = () => ({
    name: '',
    phone: '',
    email: '',
    message: '',
    agreementAccepted: 'yes',
});

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
        }),
        mixins: [
            form(),
            formSending(),
            formValidation(
                {
                    name: {
                        field: 'formData.name',
                    },
                    phone: {
                        field: 'formData.phone',
                    },
                    email: {
                        field: 'formData.email',
                        rules: {
                            ...VALIDATION_RULES.EMAIL,
                            required: true,
                        },
                    },
                    message: {
                        field: 'formData.message',
                        rules: {
                            ...VALIDATION_RULES.LARGE_TEXT,
                            required: true,
                        },
                    },
                    agreement_accepted: {
                        field: 'formData.agreementAccepted',
                        rules: {
                            validator(value) {
                                if (value !== 'yes') {
                                    return new ValidationError(this.validationMessages.AGREEMENT_NOT_ACCEPTED);
                                }
                                return true;
                            }
                        }
                    },
                },
                TRANSLATED_VALIDATION_MESSAGES
            ),
        ],
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            closeModal() {
                UIkit.modal(MODALS.FEEDBACK).hide();
            },

            sendRequest() {
                const payload = {
                    name: this.formData.name || null,
                    phone: this.formData.phone || null,
                    email: this.formData.email,
                    message: this.formData.message,
                }

                return this.sendForm(
                    () => apiFeedbackCreate(payload),
                    () => {
                        sendFeedback(payload);
                        this.successFormSending();
                        this.closeModal();
                    }
                );
            },
        },
    });
