import get from 'lodash-es/get';
import { Is } from '@library/scripts/is';
import { showErrorMessage, showSuccessMessage } from '@library/uikit/notifications';
import {
    TRANSLATED_MESSAGES,
    TRANSLATED_VALIDATION_MESSAGES,
} from '@scripts/translations';
import { SERVER_ERROR_TYPES } from '@library/forms/validation';

export const form = () => ({
    methods: {
        successFormSending(result) {
            if (Is.string(get(result, 'redirect'))) {
                window.location.href = result.redirect;
                return;
            }

            showSuccessMessage({
                text: TRANSLATED_MESSAGES.SUCCESS_FORM_SUBMITTING,
            });
            if (Is.function(this.clearForm)) {
                this.clearForm();
            }
        },

        failedFormSending(
            error,
            {
                scrollToFirstInvalidField = true,
                focusFirstInvalidField = true,
            } = {}
        ) {
            this.handleServerErrors({
                error,
                onError: this.onServerError,
                onCommonError: this.onCommonError,
                scrollToFirstInvalidField,
                focusFirstInvalidField,
            });
        },

        onCommonError(message) {
            showErrorMessage({ text: message });
        },

        onServerError(error, type) {
            if (type !== SERVER_ERROR_TYPES.API_SERVER) {
                showErrorMessage({
                    text: TRANSLATED_VALIDATION_MESSAGES.SERVER_ERROR,
                });
            }
        },
    },
});
