function sendGoogleTagManagerEvent(event, options) {
    if (window.dataLayer) {
        return new Promise((resolve, reject) => {
            window.dataLayer.push({ event, eventCallback: resolve, ...options });
            setTimeout(reject, 3000);
        });
    }

    return Promise.resolve();
}

export const sendStructureYourLoan = (formData) => sendGoogleTagManagerEvent('send_structure_your_loan', { formData });
export const sendGetLoan = (formData) => sendGoogleTagManagerEvent('send_get_loan', { formData });
export const sendFeedback = (formData) => sendGoogleTagManagerEvent('send_feedback', { formData });
export const sendBecomeLender = (formData) => sendGoogleTagManagerEvent('send_become_lender', { formData });
