import ApiClient from '@library/scripts/api/client';

// POST

export function apiFeedbackCreate(payload) {
    return ApiClient.post('/api/feedback/create', payload);
}

export function apiCreateLender(payload) {
    return ApiClient.post('/api/lender/create', payload);
}

export function apiCreateLoan(payload) {
    return ApiClient.post('/api/loan/create', payload);
}
