import '@scripts/sentry/init'; // must be placed before any other import to handle errors
import yall from 'yall-js/dist/yall';
import { initVueInstances, initModals } from '@library/scripts/register';
import { setupNotificationsDefaults } from '@library/uikit/notifications';
import { mapFromBinding } from '@scripts/helpers';
import { VUE_ELEMENTS, SLIDERS, DOCUMENT_EVENTS } from '@scripts/constants';
import { TRANSLATED_LABELS } from '@scripts/translations';
import { lockElementsOnBodyCroppingElementOpen } from '@scripts/elements-locker';
import {
    mapBecomeLenderData,
    mapGetLoanData,
    mapStructureYourLoanFormData,
} from '@scripts/mappings';
import { initMobileBurger } from '../components/header';
import initFeedbackForm from '../components/forms/feedback';
import initBecomeLenderForm from '../components/forms/become-lender';
import initGetLoanForm from '../components/forms/get-loan';
import initLoanCalculatorForm from '../components/forms/loan-calculator';
import initYoutubeVideo from '../components/youtube-video';
import { initSwiperInstances } from '../vendor/swiper/swiper';
import gallerySliderOptions from '../components/gallery-slider/options';
import clientReviewsSliderOptions from '../components/client-reviews-slider/options';
import { initContactsMap } from '../pages/contacts';
import initStructureYourLoanForm from '../components/forms/structure-your-loan';
import UIkit from '../vendor/uikit/uikit';

function lazyLoadImages() {
    yall({
        observeChanges: true,
        events: {
            load(event) {
                const parent = event.target.parentNode;
                if (parent.nodeName === 'PICTURE') {
                    parent.classList.add('yall-loaded');
                }
            },
        },
    });
}

window.UIkit = UIkit;

setupNotificationsDefaults({
    position: 'bottom-right',
    successTitle: TRANSLATED_LABELS.SUCCESS,
    errorTitle: TRANSLATED_LABELS.ERROR,
});

document.addEventListener(DOCUMENT_EVENTS.READY, () => {
    lazyLoadImages();
    initMobileBurger();
    lockElementsOnBodyCroppingElementOpen('[data-lock-on-body-crop]');
    initSwiperInstances([
        {
            selector: SLIDERS.GALLERY,
            options: gallerySliderOptions,
        },
        {
            selector: SLIDERS.CLIENT_REVIEWS,
            options: clientReviewsSliderOptions,
        },
    ]);
    initVueInstances([
        {
            query: VUE_ELEMENTS.FEEDBACK_FORM,
            name: 'feedback-form',
            init: initFeedbackForm,
        },
        {
            query: VUE_ELEMENTS.BECOME_LENDER_FORM,
            name: 'become-lender-form',
            init: initBecomeLenderForm,
            data: mapFromBinding('becomeLenderData', mapBecomeLenderData),
        },
        {
            query: VUE_ELEMENTS.GET_LOAN_FORM,
            name: 'get-loan-form',
            init: initGetLoanForm,
            data: mapFromBinding('getLoanData', mapGetLoanData),
        },
        {
            query: VUE_ELEMENTS.STRUCTURE_YOUR_LOAN_FORM,
            name: 'structure-your-loan-form',
            init: initStructureYourLoanForm,
            data: mapFromBinding(
                'landingData.structureYourLoanFormData',
                mapStructureYourLoanFormData
            ),
        },
        {
            query: VUE_ELEMENTS.LOAN_CALCULATOR_FORM,
            name: 'loan-calculator-form',
            init: initLoanCalculatorForm,
        },
        {
            query: VUE_ELEMENTS.YOUTUBE_VIDEO,
            name: 'youtube-video',
            init: initYoutubeVideo,
        },
    ]);
    initContactsMap();
});

window.addEventListener(DOCUMENT_EVENTS.LOAD, () => {
    initModals(
        UIkit,
        [
            {
                modal: '[data-uk-modal]',
                form: '[data-form]',
            },
        ],
        {
            bodyScrollLock: false,
        }
    );
});
