import get from 'lodash-es/get';
import { GLOBAL } from '@scripts/constants';
import { isFunction } from '@library/scripts/is';

export function getFromGlobalScope(path, defaultValue = {}) {
    return get(GLOBAL.APP_ENTRY_POINT, path, defaultValue);
}

export function getFromBinding(path, defaultValue = null) {
    const prefixedPath = `${GLOBAL.DEFAULT_BINDING_PREFIX}.${path}`;
    return getFromGlobalScope(prefixedPath, defaultValue);
}

export function mapFromBinding(path, mappingMethod, defaultValue = null) {
    const result = getFromBinding(path, defaultValue);
    return isFunction(mappingMethod) && result !== null
        ? mappingMethod(result)
        : result;
}
