import { Is } from '@library/scripts/is';
import { css } from 'uikit/src/js/util/style';

// source: https://gitlab.feip.co/digital/feip-dev-frontend-library/-/merge_requests/27

const CROPPING_ELEMENT_EVENTS = {
    BEFORE_SHOW: 'beforeshow',
    HIDDEN: 'hidden',
};

const CROPPING_ELEMENT_SELECTORS = ['[data-uk-modal]', '[data-uk-offcanvas]'];

function setCss(element, property, value) {
    let $element;

    if (Is.string(element)) {
        $element = document.querySelector(element);
    } else if (Is.instanceOf(element, HTMLElement)) {
        $element = element;
    }

    css($element, property, value);
}

function setMaxWidth(elements, width) {
    const $property = 'maxWidth';

    if (Is.array(elements)) {
        elements.forEach((element) => setCss(element, $property, width));
    } else if (Is.string(elements)) {
        setCss(elements, $property, width);
    } else if (Is.instanceOf(elements, HTMLElement)) {
        css(elements, $property, width);
    }
}

/**
 * Lock width of the elements that have position fixed when a body cropping
 * element (ex. modal) is going to be opened. When the body element is cropped
 * the scrollbar hides. Elements that have a fixed positioning and a relative
 * width get wider by scrollbar width which appears like annoying jumping.
 *
 * @summary Lock width of the elements that have position fixed when the body is cropped
 *
 * @param {Selector} elementsToLock
 * @param {Selector} bodyCroppingElements
 */
export function lockElementsOnBodyCroppingElementOpen(
    elementsToLock,
    bodyCroppingElements = CROPPING_ELEMENT_SELECTORS.join(',')
) {
    const modals = document.querySelectorAll(bodyCroppingElements);
    const unlockElement = () => setMaxWidth(elementsToLock, 'none');

    modals.forEach((modal) => {
        modal.addEventListener(CROPPING_ELEMENT_EVENTS.BEFORE_SHOW, () => {
            const initialWidth = document.body.clientWidth;
            setMaxWidth(elementsToLock, initialWidth);
        });

        modal.addEventListener(CROPPING_ELEMENT_EVENTS.HIDDEN, unlockElement);
        window.addEventListener('resize', unlockElement);
    });
}
