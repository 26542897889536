import Vue from 'vue/dist/vue';
import { formValidation } from '@library/forms/validation';
import { DebounceLoading } from '@library/forms/debounce-loading';
import { TRANSLATED_VALIDATION_MESSAGES } from '@scripts/translations';
import { sendStructureYourLoan } from '@scripts/analytics';
import ValueSlider from '../../value-slider/value-slider.vue';
import Dropdown from '../../dropdown/dropdown.vue';

const formDataScheme = () => ({
    propertyValue: 10000000,
    desiredLoanToValue: 75,
    propertyType: null,
    loanPurpose: null,
    minimumFixedTerm: null,
    minimumAmortization: null,
    state: null,
    county: null,
});

export default (el, name, data) =>
    new Vue({
        el,
        name,
        components: {
            ValueSlider,
            Dropdown,
        },
        data: () => ({
            formData: formDataScheme(),
            dropdownOptions: {
                propertyTypes: [],
                loanPurposes: [],
                minimumFixedTerms: [],
                minimumAmortizations: [],
                states: [],
                counties: [],
            },
            binding: data,
            submitLoading: new DebounceLoading(),
        }),
        mixins: [
            formValidation(
                {
                    property_value: {
                        field: 'formData.propertyValue',
                        rules: {
                            min: 1000000,
                            max: 100000000,
                        },
                    },
                    desired_loan_to_value: {
                        field: 'formData.desiredLoanToValue',
                        rules: {
                            min: 1,
                            max: 100,
                        },
                    },
                    property_type: {
                        field: 'formData.propertyType',
                        rules: {
                            required: true,
                        },
                    },
                    loan_purpose: {
                        field: 'formData.loanPurpose',
                        rules: {
                            required: true,
                        },
                    },
                    minimum_fixed_term: {
                        field: 'formData.minimumFixedTerm',
                        rules: {
                            required: true,
                        },
                    },
                    minimum_amortization: {
                        field: 'formData.minimumAmortization',
                        rules: {
                            required: true,
                        },
                    },
                    state: {
                        field: 'formData.state',
                        rules: {
                            required: true,
                        },
                    },
                    // its not a mistake
                    country: {
                        field: 'formData.county',
                        rules: {
                            required: true,
                        },
                    },
                },
                TRANSLATED_VALIDATION_MESSAGES,
            ),
        ],
        computed: {
            resultValue() {
                const value =
                    (this.formData.propertyValue / 100) *
                    this.formData.desiredLoanToValue;
                return `$${new Intl.NumberFormat('en-US').format(value)}`;
            },
        },
        beforeMount() {
            this.dropdownOptions = {
                loanPurposes: this.binding.loanPurposes,
                propertyTypes: this.binding.propertyTypes,
                minimumFixedTerms: this.binding.minimumFixedTerms,
                minimumAmortizations: this.binding.minimumAmortizations,
                states: this.binding.states,
                counties: [],
            };
        },
        methods: {
            sendRequest() {
                this.submitLoading.enableLoading();

                if (!this.validateFields()) {
                    this.submitLoading.disableLoading();
                    return;
                }

                const payload = {
                    property_value: this.formData.propertyValue,
                    desired_loan_to_value: this.formData.desiredLoanToValue,
                    property_type: this.formData.propertyType.value,
                    loan_purpose: this.formData.loanPurpose.value,
                    minimum_fixed_term: this.formData.minimumFixedTerm.value,
                    minimum_amortization: this.formData.minimumAmortization
                        .value,
                    state: this.formData.state.value,
                    county: this.formData.county,
                };

                const params = new URLSearchParams(payload);

                sendStructureYourLoan(payload)
                    .then(() => {
                        window.location.href = `${
                            this.binding.successRedirectRoute
                        }?${params.toString()}`;
                    });
            },
        },
        watch: {
            'formData.state': function(state) {
                this.dropdownOptions.counties = this.binding.counties[state.value];
                this.formData.county = null;
            },
        },
    });
