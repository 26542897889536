import Vue from 'vue/dist/vue';
import { formSending } from '@library/forms/form-sending';
import { formValidation } from '@library/forms/validation';
import { form } from '@scripts/mixins/form';
import { TRANSLATED_VALIDATION_MESSAGES } from '@scripts/translations';
import { sendBecomeLender } from '@scripts/analytics';
import { VALIDATION_RULES } from '@scripts/constants';
import { apiCreateLender } from '@scripts/api-methods';
import Dropdown from '../../dropdown/dropdown.vue';

const entireUsaValue = 'Entire USA';

const formDataScheme = () => ({
    name: '',
    lenderName: '',
    email: '',
    cellNumber: '',
    officePhone: '',
    loanRange: null,
    lenderType: null,
    assetsType: null,
    states: [],
});

export default (el, name, data) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
            dropdownOptions: {
                loanRanges: [],
                lenderTypes: [],
                assetsTypes: [],
                states: [],
            },
            selectedStates: [],
            binding: data,
        }),
        components: {
            Dropdown,
        },
        mixins: [
            form(),
            formSending(),
            formValidation(
                {
                    lender_name: {
                        field: 'formData.lenderName',
                        rules: {
                            required: true,
                        },
                    },
                    loan_range: {
                        field: 'formData.loanRange',
                        rules: {
                            required: true,
                        },
                    },
                    lender_type: {
                        field: 'formData.lenderType',
                        rules: {
                            required: true,
                        },
                    },
                    type_of_assets: {
                        field: 'formData.assetsType',
                        rules: {
                            required: true,
                        },
                    },
                    states: {
                        field: 'formData.states',
                        rules: {
                            required: true,
                            minLength: 1,
                        },
                    },
                    name: {
                        field: 'formData.name',
                        rules: {
                            required: true,
                        },
                    },
                    office_phone: {
                        field: 'formData.officePhone',
                    },
                    email: {
                        field: 'formData.email',
                        rules: {
                            ...VALIDATION_RULES.EMAIL,
                            required: true,
                        },
                    },
                    cell_number: {
                        field: 'formData.cellNumber',
                        rules: {
                            required: true,
                        },
                    },
                },
                TRANSLATED_VALIDATION_MESSAGES
            ),
        ],
        beforeMount() {
            this.dropdownOptions = {
                loanRanges: this.binding.lenderFormData.loanRanges,
                lenderTypes: this.binding.lenderFormData.lenderTypes,
                assetsTypes: this.binding.lenderFormData.assetsTypes,
                states: this.binding.lenderFormData.states,
            };
        },
        methods: {
            clearForm() {
                this.formData = formDataScheme();
                this.selectedStates = [];
            },

            updateSelectedStates(states) {
                if (states.length === 0) {
                    this.selectedStates = [];
                    this.formData.states = [];
                    return;
                }

                const lastSelectedState = states[states.length - 1] || {};

                if (
                    (this.selectedStates.length === 1 &&
                        this.selectedStates[0].value === entireUsaValue) ||
                    lastSelectedState.value === entireUsaValue
                ) {
                    this.selectedStates = [lastSelectedState];
                    this.formData.states = [lastSelectedState.value];
                    return;
                }

                this.selectedStates = states;
                this.formData.states = states.map((item) => item.value);
            },

            sendRequest() {
                const payload = {
                    name: this.formData.name,
                    lender_name: this.formData.lenderName,
                    email: this.formData.email,
                    cell_number: this.formData.cellNumber,
                    office_phone: this.formData.officePhone || null,
                    loan_range: this.formData.loanRange.value,
                    lender_type: this.formData.lenderType.value,
                    type_of_assets: this.formData.assetsType.value,
                    states: this.formData.states,
                }

                return this.sendForm(
                    () =>
                        apiCreateLender(payload),
                    () => {
                        this.successFormSending();
                        sendBecomeLender(payload)
                    }
                );
            },
        },
    });
