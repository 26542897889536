var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ValueSlider" }, [
    _c("input", {
      ref: "input",
      staticClass: "ValueSlider__field",
      attrs: { id: _vm.id, type: "text", maxlength: _vm.getMaxNumberLength() },
      domProps: { value: _vm.currentValue },
      on: {
        input: function($event) {
          return _vm.formatCurrentValue($event.target.value)
        },
        blur: function($event) {
          return _vm.updateValue($event)
        },
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          $event.preventDefault()
          return _vm.updateValue($event)
        }
      }
    }),
    _vm._v(" "),
    _c("div", { ref: "slider", staticClass: "ValueSlider__control" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }