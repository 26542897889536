import Vue from 'vue/dist/vue';
import { TRANSLATED_LABELS } from '@scripts/translations';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ValueSlider from '../../value-slider/value-slider.vue';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default (el, name) => new Vue({
    el,
    name,
    components: {
        ValueSlider,
    },
    data: () => ({
        formData: {
            loanAmount: 1000000,
            interestRate: 1,
            amortization: 1,
            maturity: 1,
        },
        isScheduleActual: false,
        schedule: [],
    }),
    computed: {
        principalAndInterestPayment() {
            const principal = this.formData.loanAmount;
            const rate = this.formData.interestRate / 1200;
            const months = this.formData.amortization * 12;

            if (rate === 0) {
                return principal / months;
            }

            const pow = (1 + rate)**months;

            return (principal * pow * rate) / (pow - 1);
        },

        formattedPrincipalAndInterestPayment() {
            return this.toPrice(this.principalAndInterestPayment);
        },

        interestOnlyPayment() {
            const principal = this.formData.loanAmount;
            const rate = this.formData.interestRate / 1200;

            return this.toPrice(rate * principal);
        },

        balloonPayment() {
            const principal = this.formData.loanAmount;
            const rate = this.formData.interestRate;
            const months = this.formData.maturity * 12;

            const monthlyPayment = this.principalAndInterestPayment;

            let result = principal;

            for (let i = 0; i < months; i += 1) {
                result -= monthlyPayment - Math.round(result * (rate / 12)) / 100;
            }

            return this.toPrice(result < 0 ? 0 : result / 1000, 3);
        },
    },
    methods: {
        toPrice(value, numbersAfterComma = 2) {
            const formattedValue = parseFloat(value.toFixed(numbersAfterComma));
            return `$${new Intl.NumberFormat('en-US').format(formattedValue)}`;
        },

        printSchedule() {
            const table = [];
            table.push([
                { text: TRANSLATED_LABELS.SCHEDULE_MONTH, style: 'tableHeader' },
                { text: TRANSLATED_LABELS.SCHEDULE_PAYMENT, style: 'tableHeader' },
                { text: TRANSLATED_LABELS.SCHEDULE_PRINCIPAL, style: 'tableHeader' },
                { text: TRANSLATED_LABELS.SCHEDULE_INTEREST, style: 'tableHeader' },
            ]);
            this.schedule.forEach((row) => {
                table.push([
                    { text: row.month, style: 'tableTd' },
                    { text: row.payment, style: 'tableTd' },
                    { text: row.principal, style: 'tableTd' },
                    { text: row.interest, style: 'tableTd' },
                ]);
            });
            const docDefinition = {
                content: [
                    {
                        table: {
                            widths: ['auto', '*', '*', '*'],
                            body: table,
                        }
                    },
                ],
                styles: {
                    tableTd: {
                        margin: [4, 8],
                    },
                    tableHeader: {
                        margin: [4, 8],
                        bold: true,
                    },
                },
            };
            pdfMake.createPdf(docDefinition).print();
        },

        createSchedule() {
            const principal = this.formData.loanAmount;
            const rate = this.formData.interestRate;
            const months = this.formData.amortization * 12;
            const monthlyPayment = this.principalAndInterestPayment;

            let balance = principal;
            let interest = 0;
            let monthlyPrincipal = 0;
            let payment = 0;

            this.schedule = [];

            for (let month = 1; month <= months; month += 1) {
                interest = Math.round(balance * rate / 12) / 100;

                if (month < months) {
                    monthlyPrincipal = monthlyPayment - interest;
                    balance -= monthlyPrincipal;
                } else {
                    monthlyPrincipal = balance;
                    balance = 0;
                }

                payment = monthlyPrincipal + interest;

                this.schedule.push({
                    'month': month,
                    'payment': this.toPrice(payment),
                    'principal': this.toPrice(monthlyPrincipal),
                    'interest': this.toPrice(interest),
                    'balance': this.toPrice(balance),
                });
            }

            this.isScheduleActual = true;
        },
    },
    watch: {
        'formData': {
            handler() {
                this.isScheduleActual = false;
            },
            deep: true,
        },
    },
});
