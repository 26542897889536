import { map, marker, tileLayer, icon } from 'leaflet';
import { getFromBinding } from '@scripts/helpers';

export function initContactsMap() {
    const mapData = getFromBinding('contactsData.mapData', null);

    if (mapData === null) {
        return;
    }

    const { containerId, lat, lng, zoom } = mapData;

    const coords = [lat, lng];

    const contactsMap = map(containerId, {
        center: coords,
        zoom,
    });

    tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 21,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: 'pk.eyJ1IjoibWFwcHVuaW1ha3UiLCJhIjoiY2tpYmJkNWkxMGtiazJycnR0cHltZ2JwMiJ9.1XugGbJ_Qo3smRPQ0yBNzw'
    }).addTo(contactsMap);

    const placemark = icon({
        iconUrl: '/themes/frontend/assets/icons/map-placemark.png',
        iconRetinaUrl: '/themes/frontend/assets/icons/map-placemark2x.png',
        iconSize: [32, 43],
        iconAnchor: [16, 43],
    });

    marker(coords, { icon: placemark }).addTo(contactsMap);
}
