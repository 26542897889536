export const mapStateForDropdown = (data) => ({
    label: data,
    value: data,
});

export const mapObjectEntriesForDropdown = (object) => {
    const result = [];

    Object.keys(object).forEach((key) => {
        result.push({
            label: object[key],
            value: key,
        });
    });

    return result;
};

export const mapStatesObjectForDropdown = (object) => {
    const result = [];

    Object.keys(object).forEach((key) => {
        result.push({
            label: key,
            value: key,
        });
    });

    return result;
};

export const mapCountiesFromStatesForDropdown = (object) => {
    const result = {};

    Object.keys(object).forEach((key) => {
        result[key] = object[key];
    });

    return result;
};

export const mapLenderFormData = (data) => ({
    states: data.states.map(mapStateForDropdown),
    loanRanges: mapObjectEntriesForDropdown(data.loan_ranges),
    lenderTypes: mapObjectEntriesForDropdown(data.lender_types),
    assetsTypes: mapObjectEntriesForDropdown(data.type_of_assets),
});

export const mapBecomeLenderData = (data) => ({
    lenderFormData: mapLenderFormData(data.lenderFormData),
});

export const mapStructureYourLoanFormData = (data) => ({
    successRedirectRoute: data.successRedirectRoute,
    loanPurposes: mapObjectEntriesForDropdown(data.loanPurposes),
    propertyTypes: mapObjectEntriesForDropdown(data.propertyTypes),
    minimumFixedTerms: mapObjectEntriesForDropdown(data.minimumFixedTerms),
    minimumAmortizations: mapObjectEntriesForDropdown(
        data.minimumAmortizations
    ),
    states: mapStatesObjectForDropdown(data.statesWithCountries),
    counties: mapCountiesFromStatesForDropdown(data.statesWithCountries),
});

export const mapGetLoanQuery = (data) => ({
    propertyValue: data.propertyValue,
    desiredLoanToValue: data.desiredLoanToValue,
    propertyType: data.propertyType,
    loanPurpose: data.loanPurpose,
    minimumFixedTerm: data.minimumFixedTerm,
    minimumAmortization: data.minimumAmortization,
    state: data.state,
    county: data.county,
});

export const mapGetLoanData = (data) => ({
    loanPurposes: mapObjectEntriesForDropdown(data.loanPurposes),
    query: mapGetLoanQuery(data.query),
});
