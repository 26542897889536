import UIkit from '../../vendor/uikit/uikit';

function hideOffcanvasOnNavigationClick(offcanvas) {
    offcanvas.querySelectorAll('a').forEach((link) => {
        link.addEventListener('click', () => {
            UIkit.offcanvas(offcanvas).hide();
        });
    });
}

export function initMobileBurger() {
    const offcanvas = document.querySelector('[data-mobile-offcanvas]');
    const burger = document.querySelectorAll('[data-mobile-burger]');

    if (!burger || !offcanvas) {
        return;
    }

    offcanvas.addEventListener('beforeshow', () => {
        burger.forEach((el) => {
            el.dataset.open = true;
        });
    });

    offcanvas.addEventListener('beforehide', () => {
        burger.forEach((el) => {
            el.dataset.open = false;
        });
    });

    hideOffcanvasOnNavigationClick(offcanvas);
}
