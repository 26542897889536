import Vue from 'vue/dist/vue';
import { DOCUMENT_EVENTS } from '@scripts/constants';

window.onYouTubeIframeAPIReady = () => {
    document.dispatchEvent(new Event(DOCUMENT_EVENTS.YOUTUBE_SCRIPT_LOADED));
};

export default (el, name) =>
    new Vue({
        el,
        name,
        data: () => ({
            player: null,
            video: {
                id: null,
                width: 0,
                height: 0,
            },
            isPlayed: false,
            isScriptLoaded: false,
            isLoading: false,
        }),
        beforeMount() {
            this.video = {
                id: this.$el.dataset.videoId,
                width: this.$el.dataset.width,
                height: this.$el.dataset.height,
            };

            document.addEventListener(
                DOCUMENT_EVENTS.YOUTUBE_SCRIPT_LOADED,
                () => {
                    this.isScriptLoaded = true;

                    if (this.isPlayed) {
                        this.createPlayer();
                    }
                }
            );
        },
        methods: {
            createPlayer() {
                this.player = new window.YT.Player(this.$refs.player, {
                    width: Number(this.video.width),
                    height: Number(this.video.height),
                    videoId: this.video.id,
                    events: {
                        onReady: (event) => {
                            this.isLoading = false;
                            event.target.playVideo();
                        },
                    },
                });
            },

            loadYoutubeScript() {
                const script = document.createElement('script');
                script.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag = document.querySelector('script');
                firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
            },

            play() {
                if (this.isLoading || this.player !== null) {
                    return;
                }

                this.isLoading = true;
                this.isPlayed = true;

                if (!this.isScriptLoaded) {
                    this.loadYoutubeScript();
                    return;
                }

                this.createPlayer();
            },
        },
    });
