<template>
    <div class="ValueSlider">
        <input
            :id="id"
            type="text"
            class="ValueSlider__field"
            :value="currentValue"
            @input="formatCurrentValue($event.target.value)"
            @blur="updateValue($event)"
            @keydown.enter.prevent="updateValue($event)"
            :maxlength="getMaxNumberLength()"
            ref="input"
        />

        <div
            class="ValueSlider__control"
            ref="slider"
        ></div>
    </div>
</template>

<script>
    import noUiSlider from 'nouislider';
    import { Is } from '@library/scripts/is';

    const DECIMAL_SEPARATOR = '.';
    const ONLY_DIGITS_AND_SEPARATOR = /[^0-9.]/g;

    export default {
        props: {
            id: {
                type: String,
            },
            value: {},
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                required: true,
            },
            step: {
                type: Number,
                default: 1,
            },
            formatAsPrice: {
                type: Boolean,
                default: false,
            },
            canBeFloat: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                hasDecimalSeparator: false,
                currentValue: this.value,
            };
        },
        mounted() {
            this.initSlider();
            this.$refs.input.value = this.formatValue(this.currentValue);
            this.$refs['slider'].noUiSlider.set([this.currentValue]);
        },
        methods: {
            formatValue(value) {
                let result = value;

                if (Is.string(result)) {
                    result = this.stringToInt(result);
                }

                if (this.formatAsPrice) {
                    return new Intl.NumberFormat('en-US').format(result);
                }

                return result;
            },

            stringToInt(value) {
                if (!Is.string(value)) return this.min;

                return Math.abs(Number(value.replace(/,/g, '')) || this.min);
            },

            formatCurrentValue(value) {
                if (value === '') return;

                if (!this.canBeFloat) {
                    this.$refs.input.value = this.formatValue(value);
                } else {
                    if (Is.string(value)) {
                        if (this.hasDecimalSeparator && value.split(DECIMAL_SEPARATOR).length > 2) {
                            const arr = value.split(DECIMAL_SEPARATOR);
                            value = [arr.shift(), DECIMAL_SEPARATOR, ...arr].join('');
                        }
                        this.hasDecimalSeparator = value.includes(DECIMAL_SEPARATOR);
                        value = value.replace(ONLY_DIGITS_AND_SEPARATOR, '');
                    }
                    this.$refs.input.value = value;
                }
            },

            onChangeValue(value) {
                let currentValue = value;

                if (Is.string(currentValue)) {
                    currentValue = this.stringToInt(currentValue);
                }

                this.$emit('input', currentValue);

                this.$nextTick(() => {
                    this.formatCurrentValue(currentValue);
                });
            },

            updateValue(event) {
                let { value } = event.target;

                if (value === '') {
                    value = this.min;
                }

                value = Math.min(Math.max(this.stringToInt(value), this.min), this.max);

                if (!this.canBeFloat && value % this.step !== 0) {
                    value = Math.round(value / this.step) * this.step;
                }

                this.onChangeValue(value);
                this.$refs['slider'].noUiSlider.set([value]);
            },

            initSlider() {
                const element = this.$refs['slider'];

                noUiSlider.create(element, {
                    start: 0,
                    connect: 'lower',
                    step: this.step,
                    range: {
                        'min': this.min,
                        'max': this.max,
                    },
                });

                element.noUiSlider.on('slide', (values) => {
                    this.onChangeValue(values[0]);
                });
            },

            updateSlider() {
                const element = this.$refs['slider'];

                element.noUiSlider.updateOptions({
                    step: this.step,
                    range: {
                        'min': this.min,
                        'max': this.max,
                    },
                }, true);
            },

            getMaxNumberLength() {
                return this.formatValue(this.max).length;
            },
        },
    };
</script>
