import Vue from 'vue/dist/vue';
import { formSending } from '@library/forms/form-sending';
import { formValidation, ValidationError } from '@library/forms/validation';
import { form } from '@scripts/mixins/form';
import { TRANSLATED_VALIDATION_MESSAGES } from '@scripts/translations';
import { VALIDATION_RULES } from '@scripts/constants';
import { apiCreateLoan } from '@scripts/api-methods';
import { sendGetLoan } from '@scripts/analytics';
import Dropdown from '../../dropdown/dropdown.vue';

const formDataScheme = () => ({
    name: '',
    email: '',
    phone: '',
    loanPurpose: null,
    loanAmount: '0',
    propertyValue: 0,
    desiredLoanToValue: 0,
    propertyType: null,
    minimumFixedTerm: null,
    minimumAmortization: null,
    state: null,
    county: null,
});

export default (el, name, data) =>
    new Vue({
        el,
        name,
        data: () => ({
            formData: formDataScheme(),
            dropdownOptions: {
                loanPurposes: [],
            },
            binding: data,
        }),
        components: {
            Dropdown,
        },
        mixins: [
            form(),
            formSending(),
            formValidation(
                {
                    name: {
                        field: 'formData.name',
                        rules: {
                            required: true,
                        },
                    },
                    email: {
                        field: 'formData.email',
                        rules: {
                            ...VALIDATION_RULES.EMAIL,
                            required: true,
                        },
                    },
                    phone: {
                        field: 'formData.phone',
                        rules: {
                            required: true,
                        },
                    },
                    loan_purpose: {
                        field: 'formData.loanPurpose',
                        rules: {
                            required: true,
                        },
                    },
                    loan_amount: {
                        field: 'formData.loanAmount',
                        rules: {
                            required: true,
                            validator(val) {
                                if (this.priceToInt(val) === 0) {
                                    return new ValidationError(
                                        this.validationMessages.REQUIRED_ERROR,
                                    );
                                }
                                return true;
                            },
                        },
                    },
                },
                TRANSLATED_VALIDATION_MESSAGES,
            ),
        ],
        beforeMount() {
            this.dropdownOptions = {
                loanPurposes: this.binding.loanPurposes,
            };

            this.formData = {
                ...this.formData,
                propertyValue: Number(this.binding.query.propertyValue),
                desiredLoanToValue: Number(
                    this.binding.query.desiredLoanToValue,
                ),
                propertyType: this.binding.query.propertyType,
                minimumFixedTerm: this.binding.query.minimumFixedTerm,
                minimumAmortization: this.binding.query.minimumAmortization,
                state: this.binding.query.state,
                county: this.binding.query.county,
            };

            const selectedLoanPurpose = this.binding.query.loanPurpose;

            if (selectedLoanPurpose !== '') {
                this.formData.loanPurpose = this.dropdownOptions.loanPurposes.find(
                    (item) => {
                        return item.value === selectedLoanPurpose;
                    },
                );
            }

            const selectedPropertyValue = Number(
                this.binding.query.propertyValue,
            );
            const selectedDesiredLoanToValue = Number(
                this.binding.query.desiredLoanToValue,
            );

            if (
                selectedPropertyValue !== 0 &&
                selectedDesiredLoanToValue !== 0
            ) {
                this.formData.loanAmount = this.formatPrice(
                    (selectedPropertyValue / 100) * selectedDesiredLoanToValue,
                );
            }
        },
        methods: {
            clearForm() {
                this.formData = formDataScheme();
            },

            formatPrice(value) {
                return new Intl.NumberFormat('en-US', {
                    maximumSignificantDigits: 12,
                }).format(value);
            },

            priceToInt(value) {
                return Math.abs(Number(value.replace(/,/g, ''))) || 0;
            },

            onLoanAmountInput(event) {
                this.formData.loanAmount = this.formatPrice(
                    this.priceToInt(event.target.value),
                );
            },

            sendRequest() {
                const payload = {
                    name: this.formData.name,
                    email: this.formData.email,
                    phone: this.formData.phone,
                    loan_purpose: this.formData.loanPurpose.value,
                    loan_amount: this.priceToInt(
                        this.formData.loanAmount,
                    ),
                    property_value: this.formData.propertyValue || null,
                    desired_loan_to_value:
                        this.formData.desiredLoanToValue || null,
                    property_type: this.formData.propertyType || null,
                    minimum_fixed_term:
                        this.formData.minimumFixedTerm || null,
                    minimum_amortization:
                        this.formData.minimumAmortization || null,
                    state: this.formData.state || null,
                    // not a mistake
                    country: this.formData.county || null,
                };

                return this.sendForm(
                    () =>
                        apiCreateLoan(payload),
                    () => {
                        this.successFormSending();
                        sendGetLoan(payload);
                    },
                );
            },
        },
    });
