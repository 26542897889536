import { EmailMask } from '@library/scripts/masks';

export const DOCUMENT_EVENTS = {
    READY: 'DOMContentLoaded',
    LOAD: 'load',
    YOUTUBE_SCRIPT_LOADED: 'youtubeScriptLoaded',
};

export const GLOBAL = {
    APP_ENTRY_POINT: window['sds-web'] || {},
    DEFAULT_BINDING_PREFIX: 'binding',
    DEFAULT_TRANSLATIONS_PREFIX: 'translations',
};

export const SLIDERS = {
    GALLERY: '[data-gallery-slider]',
    CLIENT_REVIEWS: '[data-client-reviews-slider]',
};

export const VUE_ELEMENTS = {
    FEEDBACK_FORM: '[data-vue-feedback-form]',
    BECOME_LENDER_FORM: '[data-vue-become-lender-form]',
    STRUCTURE_YOUR_LOAN_FORM: '[data-vue-structure-your-loan-form]',
    GET_LOAN_FORM: '[data-vue-get-loan-form]',
    LOAN_CALCULATOR_FORM: '[data-vue-loan-calculator-form]',
    YOUTUBE_VIDEO: '[data-vue-youtube-video]',
};

export const MODALS = {
    FEEDBACK: '#feedback-modal',
};

export const LABELS = {
    SUCCESS: 'form-success',
    ERROR: 'form-error',
    SCHEDULE_MONTH: 'schedule_month',
    SCHEDULE_PAYMENT: 'schedule_payment',
    SCHEDULE_PRINCIPAL: 'schedule_principal',
    SCHEDULE_INTEREST: 'schedule_interest',
};

export const MESSAGES = {
    SUCCESS_FORM_SUBMITTING: 'success-form-submitting',
};

export const VALIDATION_MESSAGES = {
    TOO_SHORT_VALUE: 'error-too-short-value',
    TOO_LONG_VALUE: 'error-too-long-value',
    TOO_SMALL_VALUE: 'error-too-small-value',
    TOO_BIG_VALUE: 'error-too-big-value',
    WRONG_VALUE_FORMAT: 'error-wrong-format',
    REQUIRED_ERROR: 'error-required',
    DEFAULT_ERROR: 'error',
    SERVER_ERROR: 'error-server',
    AGREEMENT_NOT_ACCEPTED: 'agreement-not-accepted',
};

export const VALIDATION_RULES = {
    LARGE_TEXT: {
        minLength: 3,
        maxLength: 2000,
    },
    EMAIL: {
        regexp: EmailMask.formattedRule,
    },
};
