import {
    GLOBAL,
    LABELS,
    MESSAGES,
    VALIDATION_MESSAGES,
} from '@scripts/constants';
import get from 'lodash-es/get';

/* its hack because you cant use helpers.js here !!!!!!!! */
function getFromGlobalScope(path, defaultValue) {
    return get(GLOBAL.APP_ENTRY_POINT, path, defaultValue);
}

function translate(key) {
    const prefixedPath = `${GLOBAL.DEFAULT_TRANSLATIONS_PREFIX}.${key}`;
    return getFromGlobalScope(prefixedPath, key);
}

function translateObject(obj) {
    return Object.keys(obj).reduce(
        (result, key) => ({ ...result, [key]: translate(obj[key]) }),
        {}
    );
}

export const TRANSLATED_LABELS = translateObject(LABELS);
export const TRANSLATED_MESSAGES = translateObject(MESSAGES);
export const TRANSLATED_VALIDATION_MESSAGES = translateObject(
    VALIDATION_MESSAGES
);
